<template>
<div class="user-account-form">
    <form @submit.prevent="FormType == 'add' ? addUser() : updateUser()">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="username" class="p-col-fixed" >Username:</label>
                <div class="p-col">
                    <InputText id="username" v-model="userAccountForm.username" type="text" :class="{'p-invalid':v$.username.$error || isUsernameInvalid}"/>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="password" class="p-col-fixed">Password:</label>
                <div class="p-col">
                    <Password v-model="userAccountForm.password" id="password" autocomplete="on" :feedback="false" :class="{'p-invalid':v$.password.$error}" :Placeholder="FormType == 'add' ? '' : 'Leave blank, if you dont want to change.'" toggleMask/>
                    <!-- <InputText id="password" v-model="userAccountForm.password" type="text" /> -->
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="acc_status" class="p-col-fixed">Account status:</label>
                <div class="p-col" >
                    <Dropdown v-model="selectedAccountStatus" @change="onAccountStatusChange()" :options="account_status" optionLabel="status" placeholder="Select account status" :class="{'p-invalid':v$.acc_status.$error}"/>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="user_profile" class="p-col-fixed">User profile:</label>
                <div class="p-col" >
                    <Dropdown v-model="selectedEmployeeName" @change="onEmployeeNameChange()" :options="userProfileList" optionLabel="emp_name" placeholder="Select user profile" :class="{'p-invalid':v$.emp_id.$error}"></Dropdown>
                </div>
            </div>
            <div v-if="FormType == 'add'">
                <Button v-if="can('create', 'user account')" label="Save" type="submit" class="p-button p-button-success"/>
            </div>
            <div v-else>
                <Button v-if="can('update', 'user account')" label="Update" type="submit" class="p-button p-button-warning"></Button>
            </div>
        </div>
    </form>
</div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Password from 'primevue/password';

import UserAccountService from '../service/UserAccountService';
// import EmployeeService from '../service/EmployeeService';

export default {
    props:{
        FormType: String
    },
    emits: ['RefreshUserAccountList'],
    setup(props, {emit}) {
        const { can } = useAbility()
        const store = useStore()

        let selectedUser = ref()
        const displayUserAccountForm = ref(false);
        const account_status = [{status: 'Active'}, {status: 'Inactive'}]
        let selectedAccountStatus = ref('')
        let userAccountForm = reactive({})

        let selectedEmployeeName = ref({})
        const userProfileList = ref([])
        const linked_profile = store.state.userAccount.linked_userprofile
        let user_account = reactive({})
        const isUsernameInvalid = ref(false)

        //On Created
        const userAccountService = ref(new UserAccountService());
        // const employeeService = ref(new EmployeeService());

        onMounted(() => {
            clearShowMessage()
            initUserAccountForm();
            
            if(props.FormType == 'update') {
                user_account = store.state.userAccount.user_account //Get user account data from store to fill in form
                
                selectedAccountStatus.value = {status: user_account.acc_status.charAt(0).toUpperCase() + user_account.acc_status.slice(1)} //Capitalized first letter
                setUserProfileList(user_account.emp_id)
                Object.assign(userAccountForm, user_account)
            } else {
                setUserProfileList()
            }
        })

        const initUserAccountForm = () =>{
            const initForm = {
                username: '',
                password: '',
                acc_status: '',
                emp_id: ''
            }
            selectedAccountStatus.value = ''
            selectedEmployeeName.value = ''

            Object.assign(userAccountForm, initForm)
        }

        const setUserProfileList = (EmpID) => {
            userProfileList.value = []
            selectedEmployeeName.value = {}
            userAccountService.value.getEmployeeName().then((data) =>{
                data.forEach((item) => {
                    const profile_name = item.first_name+' '+item.last_name                    
                    userProfileList.value = [...userProfileList.value, {id: item.id, emp_name: profile_name}]
                })
                
                if(EmpID){
                    const empName = userProfileList.value.filter(emp => emp.id == EmpID)
                    selectedEmployeeName.value = empName[0]
                }
            })
        }

        const addUser = () => {
            if(validateForm())  {
                userAccountService.value.addUserAccount(userAccountForm).then((data) =>{
                    if(data.errorResponse) {
                        showMessage('error', data.errorResponse, 4000)
                    } else {
                        if(data.status == 400) {
                            isUsernameInvalid.value = true
                            return showMessage('warn', data.message)
                        }

                        initUserAccountForm()
                        emit('RefreshUserAccountList')
                        showMessage('success', 'User successfully added.', 2500)
                        isUsernameInvalid.value = false
                        v$.value.$reset() //Reset validations
                    }
                })
            }           
        }

        const updateUser = () => {
            if(validateForm()) {
                const updateData = {
                    username: userAccountForm.username == user_account.username ? '':userAccountForm.username,
                    password: userAccountForm.password,
                    acc_status: userAccountForm.acc_status,
                    emp_id: userAccountForm.emp_id,
                }
                
                userAccountService.value.updateUserAccount(userAccountForm.id, updateData).then((data) => {
                    if(data.errorResponse) {
                        showMessage('error', 'Update error '+data.status+': '+data.errorResponse, 4000)
                    } else {
                        if(data.status == 400) {
                            isUsernameInvalid.value = true
                            return showMessage('warn', data.message)
                        }

                        emit('RefreshUserAccountList')
                        showMessage('success', 'User successfully updated.', 2500)
                        isUsernameInvalid.value = false
                        // v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const onAccountStatusChange = () => {
            userAccountForm.acc_status = selectedAccountStatus.value.status.toLowerCase()
        }

        const onEmployeeNameChange = () => {
            const profile = linked_profile.find(val => val.profile == selectedEmployeeName.value.emp_name)
            if(profile) {
                showMessage('warn', 'This profile is already linked to an account.')
                userAccountForm.emp_id = ''
            }
            if(!profile || profile.profile == user_account.user_profile) {
                clearShowMessage()
                userAccountForm.emp_id = selectedEmployeeName.value.id
            }
        }

        const showMessage = (severity, msgContent, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: msgContent
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        const clearShowMessage = () => {
            store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
        }

        //Form Validations
        let rules = computed(() =>  {
            return {
                username: { required },
                password: props.FormType == 'add' ? { required } : {},
                acc_status: { required },
                emp_id: { required },
            }
        })
        const v$ = useVuelidate(rules, userAccountForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            can,
            v$,
            selectedUser,
            displayUserAccountForm,
            account_status,
            selectedAccountStatus,
            userAccountForm,
            isUsernameInvalid,

            selectedEmployeeName, 
            userProfileList,
            onEmployeeNameChange,

            addUser,
            updateUser,
            onAccountStatusChange,
        }
    },
    components: {
        Password
    }
}
</script>

<style lang="scss" scoped>
.user-account-form {
    margin: 1rem .5rem;
}
.user-account-form {
    label {
        width: 130px;
        margin: 0;
    }
}
</style>